<template>
    <div class="toBePaid">
        <div class="toBePaid-head">
         <span class="iconfont icon-fanhuijiantou" @click="goHistotry()"></span>
      </div>
    </div>
</template>

<script>
export default {
    name: 'toBeShipped',
    methods: {
        goHistotry() {
            this.$router.go(-1);
        }
    },
}
</script>

<style scoped>
.toBePaid-head{
    height: 44px;
    line-height: 44px;
    background: rgba(0,129,255);
}
.toBePaid-head .icon-fanhuijiantou {
    font-size: 20px;
    color: #fff;
    padding-left: 10px;
}
</style>